<template>
  <div class="down">
    <p class="font">Please select your mobile app store</p>
    <div class="item_downloads flex fd-c jc-c ai-c">
      <img src="@/assets/images/loanImg/Google-play.png" @click="downloadApp(1)">
      <img src="@/assets/images/loanImg/Apple-store.png" @click="downloadApp(2)">
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    downloadApp(type) {
      switch (type) {
        case 1:
          window.open('https://play.google.com/store/apps/details?id=micro.loan.app', '_blank')
          break
        case 2:
          window.open('https://apps.apple.com/us/app/micro-loan/id1664036395', '_blank')
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.down{
  background: white;
  position: absolute;
  top: 80px;
  right: -135px;
  color: black;
  padding: 40px 30px 50px;
  border-radius: 15px;
  font-size: 25px;
  font-weight: bold;
  &:before {
    content: '';
    position: absolute;
    top: -20px;
    left: calc(50% - 15px);
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 25px solid #fff;
  }
  .item_downloads{
    img{
      width: 150px;
      height: 45px;
      margin-top: 25px;
    }
  }
}
@media only screen and (max-width: 720px){
}
</style>
